.blogRowSpacer {
  height: 15px;
}

.title {
  color: white;
  position: absolute;
  top: 60px;
}

body {

}

h3 {
  padding: 15px;
}

h4 {
  padding: 15px;
}

@media only screen and (max-width: 1000px) {
    h3 {
        padding: 10px;
    }
}

.inverse-subtitle {
  color: white;
}

.comingSoon {
  padding: 200px;
  font-size: 24px;
}

.aStyle {
  text-decoration: none;
}

.aStyle:hover {
  text-decoration: none;
}

.hrStyle {
  border-top: 5px solid #555;
}

.icon-group {
  padding: 25px;
  border-style: solid;
  border-width: 3px;
  margin-bottom: 10px;
}

.image-blurb {
  padding-bottom: 15px;
}
.social-blurb {
  padding-bottom: 15px;
  padding-left: 15px;
}

.icon-spacer {
  padding-left: 5px;
}
.icon-spacer {
  padding-right: 10px;
}
